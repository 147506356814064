var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"full-width overflow-y-auto d-flex"},[_c('v-card',{staticClass:"d-flex flex-column flex-grow-1 mr-5 overflow-y-auto",attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_vm._v("Opdrachtgever informatie")]),_c('v-card-text',{staticClass:"flex-grow-1 overflow-y-auto"},[(_vm.clientData)?_c('v-form',[_c('v-row',[_c('v-col',{staticClass:"flex-grow-1"},[_c(_setup.AntInput,{attrs:{"label":"Opdrachtgever","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{attrs:{"filled":"","placeholder":"Opdrachtgever"},on:{"change":(value) => _vm.updateClientData('company', value)},model:{value:(_vm.clientData.company),callback:function ($$v) {_vm.$set(_vm.clientData, "company", $$v)},expression:"clientData.company"}})]},proxy:true}],null,false,199476542)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"flex-grow-1"},[_c(_setup.AntInput,{attrs:{"label":"Offerte Type","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-select',{attrs:{"filled":"","items":[
                    'Huur',
                    'Koop',
                    'Renovatie',
                    'Verplaatsing',
                    'Huur Koop',
                    'Huur en Koop',
                  ],"placeholder":"Type"},on:{"change":(value) => _vm.updateClientData('offer_type', value)},model:{value:(_vm.clientData.offer_type),callback:function ($$v) {_vm.$set(_vm.clientData, "offer_type", $$v)},expression:"clientData.offer_type"}})]},proxy:true}],null,false,557368765)})],1),_c('v-col',[_c(_setup.AntInput,{attrs:{"label":"Offerte module status","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-select',{attrs:{"filled":"","items":['Nieuw', 'Gebruikt', 'Nieuw/Gebruikt'],"placeholder":"Module status"},on:{"change":(value) => _vm.updateClientData('offered_module_state', value)},model:{value:(_vm.clientData.offered_module_state),callback:function ($$v) {_vm.$set(_vm.clientData, "offered_module_state", $$v)},expression:"clientData.offered_module_state"}})]},proxy:true}],null,false,4026207655)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"flex-grow-1"},[_c(_setup.AntInput,{attrs:{"label":"Begroting","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{attrs:{"filled":"","type":"number","placeholder":"Hoeveelheid in euro's","prepend-inner-icon":"mdi-currency-eur"},on:{"change":(value) => _vm.updateClientData('calculation_amount', value)},model:{value:(_vm.clientData.calculation_amount),callback:function ($$v) {_vm.$set(_vm.clientData, "calculation_amount", $$v)},expression:"clientData.calculation_amount"}})]},proxy:true}],null,false,606871615)})],1),_c('v-col',[_c(_setup.AntInput,{attrs:{"label":"Geaccepteerde begroting","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{attrs:{"filled":"","placeholder":"Hoeveelheid in euro's","prepend-inner-icon":"mdi-currency-eur"},on:{"change":(value) =>
                      _vm.updateClientData('agreed_calculation_amount', value)},model:{value:(_vm.clientData.agreed_calculation_amount),callback:function ($$v) {_vm.$set(_vm.clientData, "agreed_calculation_amount", $$v)},expression:"clientData.agreed_calculation_amount"}})]},proxy:true}],null,false,982516664)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"flex-grow-1"},[_c(_setup.AntInput,{attrs:{"label":"Minimale Marge","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{attrs:{"filled":"","placeholder":"Marge","prepend-inner-icon":"mdi-percent"},on:{"change":(value) => _vm.updateClientData('minimal_margin', value)},model:{value:(_vm.clientData.minimal_margin),callback:function ($$v) {_vm.$set(_vm.clientData, "minimal_margin", $$v)},expression:"clientData.minimal_margin"}})]},proxy:true}],null,false,1702504123)})],1),_c('v-col',[_c(_setup.AntInput,{attrs:{"label":"Tender","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-switch',{attrs:{"filled":""},on:{"change":(value) => _vm.updateClientData('tender', value)},model:{value:(_vm.clientData.tender),callback:function ($$v) {_vm.$set(_vm.clientData, "tender", $$v)},expression:"clientData.tender"}})]},proxy:true}],null,false,341635967)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"flex-grow-1"},[_c(_setup.AntInput,{attrs:{"label":"Bouwteamovereenkomst","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-select',{attrs:{"filled":"","items":['Ja', 'Nee'],"placeholder":"Bouwteamovereenkomst Ja of Nee"},on:{"change":(value) =>
                      _vm.updateClientData('construction_agreement', value)},model:{value:(_vm.clientData.construction_agreement),callback:function ($$v) {_vm.$set(_vm.clientData, "construction_agreement", $$v)},expression:"clientData.construction_agreement"}})]},proxy:true}],null,false,4233974229)})],1),_c('v-col',[_c(_setup.AntInput,{attrs:{"label":"Engineering","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{attrs:{"filled":"","placeholder":"Engineering"},on:{"change":(value) => _vm.updateClientData('engineering', value)},model:{value:(_vm.clientData.engineering),callback:function ($$v) {_vm.$set(_vm.clientData, "engineering", $$v)},expression:"clientData.engineering"}})]},proxy:true}],null,false,2809698131)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"flex-grow-1"},[_c(_setup.AntInput,{attrs:{"label":"Vergunningaanvrager","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-select',{attrs:{"filled":"","items":['Intern', 'Opdrachtgever'],"placeholder":"Aanvrager"},on:{"change":(value) => _vm.updateClientData('permit_applicant', value)},model:{value:(_vm.clientData.permit_applicant),callback:function ($$v) {_vm.$set(_vm.clientData, "permit_applicant", $$v)},expression:"clientData.permit_applicant"}})]},proxy:true}],null,false,2571550607)})],1),_c('v-col',[_c(_setup.AntInput,{attrs:{"label":"Vergunningsduur","top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-select',{attrs:{"filled":"","items":[
                    'Permanent > 10 jaar (NL)',
                    'Tijdelijk t/m 10 jaar (NL)',
                    'Tijdlijk t/m 15 jaar (NL)',
                    'Permanent > 2 jaar (BE)',
                    'Tijdlijk < 2 jaar (BE)',
                  ],"placeholder":"Duur"},on:{"change":(value) =>
                      _vm.updateClientData('build_permit_duration', value)},model:{value:(_vm.clientData.build_permit_duration),callback:function ($$v) {_vm.$set(_vm.clientData, "build_permit_duration", $$v)},expression:"clientData.build_permit_duration"}})]},proxy:true}],null,false,2656115623)})],1)],1)],1):_vm._e()],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"flex-grow-1",attrs:{"items":_vm.clients,"headers":[
        { text: 'Naam', value: 'name' },
        { text: 'rol', value: 'role' },
        { text: 'Bedrijf', value: 'company' },
        { text: '', value: 'actions' },
      ]},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-icon',{on:{"click":function($event){return _vm.removeClient(item)}}},[_vm._v("mdi-delete")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-toolbar-title',[_vm._v("Stakeholders")]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","color":"primary","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Voeg toe")],1)]}}]),model:{value:(_vm.addMenu),callback:function ($$v) {_vm.addMenu=$$v},expression:"addMenu"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center text-uppercase headline"},[_vm._v(" Add client ")]),_c('v-divider'),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"px-10 pb-5"},[_c(_setup.AntInput,{attrs:{"label":"Name","is-optional":false},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{attrs:{"hide-details":"","dense":"","rules":[_vm.rules.required, _vm.rules.maxLength],"filled":"","single-line":"","autofocus":true,"placeholder":"Name","disabled":!_vm.clients},on:{"change":(name) => (_vm.client.name = name)},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}})]},proxy:true}])}),_c(_setup.AntInput,{attrs:{"label":"Role","is-optional":false},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-select',{attrs:{"items":[
                          'Tekenbevoegd',
                          'Projectontvanger',
                          'Architect',
                          'Adviseur',
                          'Contactpersoon klant',
                        ],"hide-details":"","rules":[_vm.rules.required, _vm.rules.maxLength],"dense":"","filled":"","single-line":"","placeholder":"Role","disabled":!_vm.clients},on:{"change":(role) => (_vm.client.role = role)},model:{value:(_vm.client.role),callback:function ($$v) {_vm.$set(_vm.client, "role", $$v)},expression:"client.role"}})]},proxy:true}])}),_c(_setup.AntInput,{attrs:{"label":"Company","is-optional":false},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{attrs:{"hide-details":"","rules":[_vm.rules.required, _vm.rules.maxLength],"dense":"","filled":"","single-line":"","placeholder":"Company","disabled":!_vm.clients},on:{"change":(role) => (_vm.client.company = role),"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addClientAction.apply(null, arguments)}},model:{value:(_vm.client.company),callback:function ($$v) {_vm.$set(_vm.client, "company", $$v)},expression:"client.company"}})]},proxy:true}])})],1),_c('v-card-actions',{staticClass:"ant-border-top ant-dialog-actions-bg"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.resetForm.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","small":"","elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.addClientAction.apply(null, arguments)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }